<template>
  <div class="userAbout">
    <div class="userAbout-title">个人信息</div>
    <van-form>
      <van-cell-group inset>
        <van-field v-model="form.name" name="name" label="用户名：" readonly />
        <van-field v-model="form.wechat" name="name" label="微信号：" readonly />
        <van-field v-model="form.cellphone" name="name" label="手机号：" readonly />
        <van-field v-model="form.adviser_name" name="name" label="投放顾问："
          readonly />
      </van-cell-group>
    </van-form>
  </div>
</template>

<script>
import sk from './img/sk.png'
import { getUserInfo } from '@/api/information.js'
export default {
  name: 'userAbout',
  data() {
    return {
      form: {},
      sk,
      showArea: false
    }
  },
  async activated() {
    let res = await getUserInfo()
    this.form = res
  },
  methods: {
    onConfirm(areaValues) {
      this.showArea = false
      this.form.city = areaValues
        .filter(item => !!item)
        .map(item => item.name)
        .join('/')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-cell-group--inset {
  margin: 0px;
}
/deep/ .van-field__label {
  font-weight: bold;
}
/deep/ .van-field__control {
  color: #666;
}
.userAbout {
  padding: 0px 0px;
  /deep/ .van-cell {
    font-size: 26px;
  }
}
.userAbout-title {
  padding: 20px 0px;
  margin-bottom: 40px;
  border-radius: 10px;
  background: white;
}
.jumpWithdrawal {
  padding: 20px 60px 20px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  background: white;
  font-weight: bold;
  .jlabel {
    display: flex;
    align-items: center;
  }
  img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
}
</style>
